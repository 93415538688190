export const environment = {
  production: true,
  demo: false,
  passwordResetLink: "https://api.bluectrl.app/auth/jwt/login",
  bluectrlapi: "https://api.bluectrl.at",
  ModuleWifiSurFix: "BLUELINE_",
  DefaultModuleIP: "192.168.1.10",
  DefaultModuleApi: "https://192.168.1.10:8443/",
  DefaultModuleWifiKey: "BLUELINE_FTW",
  ClusterWifiSurFix: "BLUECTRL_",
  DefaultClusterWifiKey: "SdHnRPCM8962c3JY",
  GrafanaClusterLogUrl:
    "https://monitor.bluectrl.at/d/Sf1ZRVS4z/bluectrl-cluster-logging?orgId=1&var-clusterid=",
};
